import React from "react";
import { Button } from "@mui/material";

import notConnected from "./images/not-connected.png";

export default function NotConnected() {
  return (
    <div
      style={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "600px",
          margin: "15vh 0 5vh",
          padding: "24px",
        }}
      >
        <img src={notConnected} width={"100%"} />
        <div
          style={{
            textAlign: "center",
            paddingTop: "12px",
            fontSize: "16px",
            color: "black",
            fontWeight: "500",
          }}
        >
          <div>Có lỗi xảy ra</div>
          <div>Vui lòng kiểm tra kết nối và thử lại</div>
        </div>
      </div>
      <Button
        style={{
          width: "45%",
          margin: "auto",
          background: "#0B3C8A",
          color: "white",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "600",
          borderRadius: "16px",
        }}
        onClick={() => {
          window.location.reload();
        }}
      >
        Thử lại
      </Button>
    </div>
  );
}
