import React, { useContext, useState } from "react";
import { Modal, Button } from "antd";
import HuongDan from "./../images/warning.png";
import { AuthContext } from "./../index";

const Warning = (props) => {
  const { UploadOrder, registeredType } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const css = {
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "15px",
      alignItems: "center",
    },
    button: {
      width: "46%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    },
  };
  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        style={{
          margin: 0,
          padding: 0,
        }}
        className="modal-custom"
        centered={true}
        footer={false}
        open={props.isModalVisible}
        closable={false}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={HuongDan} style={{ width: "70px" }} />
          <div
            style={{
              fontSize: 18,
              marginTop: 10,
              fontWeight: 600,
              color: "#383D3B",
            }}
          >
            Thông báo
          </div>
          {registeredType === 2 ? (
            <div>
              <div
                style={{
                  fontSize: 16,
                  marginTop: 10,
                  color: "#515855",
                  textAlign: "justify",
                }}
              >
                Các tổn thất xảy ra trước ngày được bảo hiểm sẽ không thuộc phạm
                vi chi trả của đơn này.
                <br />
              </div>
              <div
                style={{
                  fontSize: 16,
                  marginTop: 10,
                  color: "#515855",
                  textAlign: "left",
                }}
              >
                Bạn có muốn gửi hình ảnh xe không?
              </div>
            </div>
          ) : (
            <div
              style={{
                fontSize: 16,
                marginTop: 10,
                color: "#515855",
                textAlign: "justify",
              }}
            >
              Hình ảnh đã chụp là căn cứ để thực hiện cấp đơn, bạn vui lòng kiểm
              tra trước khi xác nhận gửi.
            </div>
          )}

          <div style={css.divButton}>
            <Button
              onClick={() => props.handleCancel()}
              style={{
                ...css.button,
                background: "#fff",
                color: "#009B4B",
                borderColor: "#009B4B",
              }}
            >
              Quay lại
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                UploadOrder();
                setLoading(true);
              }}
              style={css.button}
            >
              Gửi
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Warning;
