import React, { useRef, useEffect, useContext, useState } from "react";
import { Button, Row, Col, Image } from "antd";
import { Animated } from "react-animated-css";
import Icamera from "./images/camera.svg";
import Close from "./images/close.png";
import CameraOrder from "./images/cameraOrder.svg";
import Step from "./images/step.svg";
import Fail from "./modal/fail";
import Warning from "./modal/warning";
import Success from "./modal/success";
import { AuthContext } from "./index";

const ViewAndComplete = (props) => {
  const {
    compressIMG,
    listImg,
    postMessage,
    DeleteOther,
    UploadOrder,
    isModalVisibleFail,
    setIsModalVisibleFail,
    isModalSuccess,
    setIsModalSuccess,
    isModalWarning,
    setIsModalWarning,
    registeredType,
    DeleteDocumentCar
  } = useContext(AuthContext);
  const [keyEdit, setKeyEdit] = useState("");
  const importInputRef = useRef();

  const handleCancel = () => {
    setIsModalVisibleFail(false);
    setIsModalSuccess(false);
    setIsModalWarning(false);
  };

  const handleImportFile = (files) => {
    window.scrollTo(0, 0);
    if (files && files[0]) {
      compressIMG(files[0], "vat-chat-oto", keyEdit);
    }
  };

  const css = {
    divButton: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      height: "80px",
      alignItems: "center",
      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "16px 16px 0px 0px",
      background: "#ffffff",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    },
    divTitle: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "uppercase",
      paddingTop: "6%",
    },
    Item: {
      fontSize: 14,
      fontWeight: 600,
      textAlign: "left",
      padding: "0px 0px 5px 0px",
      marginTop: -5,
    },
    content: {
      borderTop: "none",
      borderRadius: "0px 0px 8px 8px",
      height: "85%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    camera: {
      position: "absolute",
      top: "50%",
      background: "#fff",
      right: "40%",
      padding: 5,
      borderRadius: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    close: {
      top: 5,
      position: "absolute",
      background: "#fff",
      right: 5,
      padding: 5,
      borderRadius: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    col: {
      position: "relative",
      marginTop: 10,
      zIndex: 1,
    },
  };

  const arrImg = [
    {
      title: "Góc trước - bên trái",
      img: listImg?.frontLeft,
      keyName: "frontLeft",
    },
    {
      title: "Góc trước - bên phải",
      img: listImg?.frontRight,
      keyName: "frontRight",
    },
    {
      title: "Góc sau - bên trái",
      img: listImg?.backLeft,
      keyName: "backLeft",
    },
    {
      title: "Góc sau - bên phải",
      img: listImg?.backRight,
      keyName: "backRight",
    },
    {
      title: "Tem đăng kiểm",
      img: listImg?.inspectionStamps,
      keyName: "inspectionStamps",
    },
    {
      title: "Hình công tơ mét",
      img: listImg?.speedometer,
      keyName: "speedometer",
    },
    // { title: "Hình bổ sung", img: listImg?.other, keyName: "other" },
  ];

  return (
    <>
      <Warning handleCancel={handleCancel} isModalVisible={isModalWarning} />
      <Fail handleCancel={handleCancel} isModalVisible={isModalVisibleFail} />
      <Success handleCancel={handleCancel} isModalVisible={isModalSuccess} />
      <img src={Step} style={{ width: "100%" }} />
      <Animated
        animationIn="slideInRight"
        animationInDuration={500}
        isVisible={true}
        style={{ with: "100%" }}
      >
        <div style={{ width: "100%", color: "#343A40" }}>
          <div
            style={{ textAlign: "center", width: "95%", margin: "0px auto" }}
          >
            <div style={css.divTitle}>BẠN ĐÃ CHỤP HÌNH THÀNH CÔNG</div>
            {registeredType === 2 && (
              <div>
                Các hình ảnh này đã đúng với tình trạng xe của bạn hiện tại
              </div>
            )}
          </div>
          <div style={{ padding: "0px 2%" }}>
            <Row gutter={10}>
              {(arrImg || []).map((data, i) => (
                <Col
                  key={i}
                  xs={12}
                  style={{ ...css.col, display: data.img ? "" : "none" }}
                >
                  <div>
                    <div style={css.Item}>{data.title}</div>
                    <div style={css.content}>
                      <Image
                        width={"100%"}
                        height={"150px"}
                        src={data.img}
                        style={{ overflow: "scroll", borderRadius: 3 }}
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setKeyEdit(data.keyName);
                      setTimeout(() => {
                        importInputRef.current.click();
                      }, 300);
                    }}
                    style={css.camera}
                  >
                    <img src={Icamera} style={{ width: "30px" }} />
                  </div>
                </Col>
              ))}
            </Row>
            <div style={{ fontWeight: 600, marginTop: "10px" }}>
              {registeredType === 2
                ? "CHỤP HÌNH ẢNH BỔ SUNG"
                : "HÌNH ẢNH HOÁ ĐƠN CHỨNG TỪ"}
            </div>
            {registeredType === 2 && (
              <div style={{ height: 400 }}>
                <Row gutter={10}>
                  <Col xs={6} style={{ marginTop: 5 }}>
                    <div style={{ width: "100%", height: "110px" }}>
                      <img
                        onClick={() => {
                          setKeyEdit("other");
                          setTimeout(() => {
                            importInputRef.current.click();
                          }, 300);
                        }}
                        src={CameraOrder}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Col>
                  {(listImg.other || []).map((data, i) => (
                    <Col
                      xs={6}
                      style={{ marginTop: i + 1 > 3 ? "0px" : "5px" }}
                    >
                      <Animated
                        animationIn="fadeInRight"
                        animationInDuration={2000}
                        isVisible={true}
                        style={{ with: "100%", height: "100%" }}
                      >
                        <div style={{ width: "100%", height: "100%" }}>
                          <Image
                            src={data}
                            // height={"100%"}
                            width={"100%"}
                            height={"112px"}
                            style={{
                              borderRadius: 8,
                              width: "100%",
                              height: "75%",
                              // marginLeft: 10,
                            }}
                          />
                        </div>
                        <div
                          onClick={() => {
                            DeleteOther(i);
                          }}
                          style={css.close}
                        >
                          <img src={Close} style={{ width: "14px" }} />
                        </div>
                      </Animated>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            {registeredType !== 2 && (
              <div style={{ height: 400 }}>
                <Row gutter={10}>
                  <Col xs={6} style={{ marginTop: 5 }}>
                    <div style={{ width: "100%", height: "110px" }}>
                      <img
                        onClick={() => {
                          setKeyEdit(registeredType === 1?'invoices':'certificate');
                          setTimeout(() => {
                            importInputRef.current.click();
                          }, 300);
                        }}
                        src={CameraOrder}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Col>
                  {(
                    listImg.invoices ||
                    listImg.certificate ||
                    []
                  ).map((data, i) => (
                    <Col
                      xs={6}
                      style={{ marginTop: i + 1 > 3 ? "0px" : "5px" }}
                    >
                      <Animated
                        animationIn="fadeInRight"
                        animationInDuration={2000}
                        isVisible={true}
                        style={{ with: "100%", height: "100%" }}
                      >
                        <div style={{ width: "100%", height: "100%" }}>
                          <Image
                            src={data}
                            // height={"100%"}
                            width={"100%"}
                            height={"112px"}
                            style={{
                              borderRadius: 8,
                              width: "100%",
                              height: "75%",
                              // marginLeft: 10,
                            }}
                          />
                        </div>
                        <div
                          onClick={() => {
                            DeleteDocumentCar(i,registeredType === 1?'invoices':'certificate');
                          }}
                          style={css.close}
                        >
                          <img src={Close} style={{ width: "14px" }} />
                        </div>
                      </Animated>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </div>
      </Animated>
      <div style={{ ...css.divButton, position: "fixed", bottom: 0 }}>
        <Button
          onClick={() => {
            // UploadOrder();
            setIsModalWarning(true);
          }}
          style={css.button}
        >
          Xác nhận
        </Button>
      </div>
      <input
        ref={importInputRef}
        capture="camera"
        type="file"
        accept="image/*"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => handleImportFile(e.target.files)}
        style={{
          display: "none",
        }}
      />
    </>
  );
};

export default ViewAndComplete;
