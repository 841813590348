import React, { useRef, useEffect, useContext } from "react";
import { Button } from "antd";
import { Animated } from "react-animated-css";
import HuongDan from "./images/hdchupgiayto.png";
import Camera from "./images/cameraOrder.svg";

import { AuthContext } from "./index";

const VehicleRegistration = (props) => {
  const { compressIMG, registeredType} = useContext(AuthContext);
  const importInputRef = useRef();

  useEffect(() => {}, []);

  const handleImportFile = (files) => {
    if (files && files[0]) {
      compressIMG(files[0], "vat-chat-oto", registeredType===1?"invoices":"certificate");
    }
  };

  const css = {
    divButton: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      bottom: 0,
      height: "80px",
      alignItems: "center",
      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "16px 16px 0px 0px",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    },
    divTitle: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "uppercase",
      paddingTop: "6%",
    },
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationInDuration={500}
        isVisible={true}
        style={{ with: "100%", height: "100%" }}
      >
        <div style={{ width: "100%", height: "100%", color: "#343A40" }}>
          <div
            style={{
              padding: "20px 10px 10px 10px",
              textAlign: "center",
              fontSize: 18,
              fontWeight: 600
            }}
          >
            Hướng dẫn chụp hỉnh ảnh
          </div>
          <img src={HuongDan} style={{ width: "100%", padding:'10px 20px'}} />
          <div style={{ margin: "10px 20px" }}>
            <div style={{ color: "#515855" }}>
              <b>CHỤP HÌNH ẢNH</b>
            </div>
            <div style={{ color: "#646D69" }}>
              Đảm bảo hình ảnh chính xác với thời điểm hiện tại
            </div>
          </div>
          <img
            onClick={() => {
              importInputRef.current.click();
            }}
            src={Camera}
            style={{ width: 80, margin: "10px 20px" }}
          />
        </div>
      </Animated>
      <input
        ref={importInputRef}
        capture="camera"
        type="file"
        accept="image/*"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => handleImportFile(e.target.files)}
        style={{
          display: "none",
        }}
      />
    </>
  );
};

export default VehicleRegistration;
