import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";
import CarCapture from "./components/carCapture";
import Connect from "./components/error-page/not-connected";
function App() {
  const conect = window.navigator.onLine;
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route component={conect?CarCapture:Connect} path="/" />
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
