import React, { useRef, useEffect, useContext } from "react";
import { Button } from "antd";
import { Animated } from "react-animated-css";
import Temdangkiem from "./images/dangkiem.svg";
import { AuthContext } from "./index";

const InspectionStamps = (props) => {
  const { compressIMG } = useContext(AuthContext);
  const importInputRef = useRef();

  useEffect(() => {}, []);

  const handleImportFile = (files) => {
    if (files && files[0]) {
      compressIMG(files[0], "vat-chat-oto","inspectionStamps");
    }
  };

  const css = {
    divButton: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "fixed",
      bottom: 0,
      height: "80px",
      alignItems: "center",
      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "16px 16px 0px 0px",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    },
    divTitle: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: "uppercase",
      paddingTop: "6%",
    },
  };

  return (
    <>
      <Animated
        animationIn="slideInRight"
        animationInDuration={500}
        isVisible={true}
        style={{ with: "100%", height: "100%" }}
      >
        <div style={{ width: "100%", height: "100%", color: "#343A40" }}>
          <div
            style={{ textAlign: "center", width: "95%", margin: "0px auto" }}
          >
            <div style={css.divTitle}>Chụp hình TEM ĐĂNG KIỂM</div>
            <div>
              Hình chụp phải nằm chính giữa khung hình, đảm bảo không bị mất góc
              hay bị che chắn.
            </div>
          </div>
          <div>
            <img
              src={Temdangkiem}
              style={{ width: "100vw", margin: "6% 0px" }}
            />
          </div>
          <div style={css.divButton}>
            <Button
              onClick={() => importInputRef.current.click()}
              style={css.button}
            >
              Chụp ảnh
            </Button>
          </div>
        </div>
      </Animated>
      <input
        ref={importInputRef}
        capture="camera"
        type="file"
        accept="image/*"
        onClick={(e) => {
          e.target.value = "";
        }}
        onChange={(e) => handleImportFile(e.target.files)}
        style={{
          display: "none",
        }}
      />
    </>
  );
};

export default InspectionStamps;
