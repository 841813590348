import moment from "moment";


function getParamUrlByKey(keyparame) {
    let search = window.location.search;
    let arr = search.split('?')
    let params = new URLSearchParams(arr[1]);
    let res;

    if (keyparame) {
        res = params.get(keyparame);
    }
    return res;
};

function _formatDay(str) {
    if (str) {
        return moment.utc(str).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
};

function _formatH(str) {
    if (str) {
        return moment(str).format('h:mm a');
    }
    return '';
};

const variable = {
    getParamUrlByKey,
    _formatH,
    _formatDay
};
export default variable;