// build dev
const server = process.env.REACT_APP_G2;
const serverCore = process.env.REACT_APP_CORE;

//build beta
// const server = 'https://g2-beta.globalcare.vn/';
// const serverCore = 'https://core-beta.globalcare.vn/';


// build live
// const server = 'https://g2.globalcare.vn/';
// const serverCore = 'https://core.globalcare.vn/';

export {
    server,
    serverCore
};