import React, { useRef, useEffect, useContext } from "react";
import { Animated } from "react-animated-css";
import Loadding from "./images/loading.svg";
import Chopheduyet from "./images/choduyet.svg";
import Hoantat from "./images/hoantat.svg";
import Khongdudk from "./images/khongdudk.svg";
import Load from "./images/load.gif";
import { AuthContext } from "./index";

const FrontLeft = (props) => {
  const { statusCheckOrder } = useContext(AuthContext);

  useEffect(() => {}, []);

  const css = {};

  return (
    <>
      <Animated
        animationIn="slideInUp"
        animationInDuration={500}
        isVisible={true}
        style={{ with: "100%", height: "100%" }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            color: "#646D69",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {statusCheckOrder === 5 && (
            <div style={{ textAlign: "center", lineHeight: 2 }}>
              <img src={Loadding} />
              <div>
                <img src={Load} style={{ width: "60px" }} />
              </div>
              <div
                style={{ color: "#515855", marginTop: -10, fontWeight: 600 }}
              >
                ĐANG KIỂM TRA
              </div>
              <div>Đơn hàng đang được kiểm tra...</div>
            </div>
          )}

          {+statusCheckOrder !== 2 &&
            +statusCheckOrder !== 3 &&
            +statusCheckOrder !== 5 && (
              <div style={{ textAlign: "center" }}>
                <img src={Khongdudk} />
                <div style={{ marginTop: 15 }}>
                  Đơn hàng chưa đủ điều kiện chụp hình
                </div>
              </div>
            )}

          {+statusCheckOrder === 2 && (
            <div style={{ textAlign: "center" }}>
              <img src={Chopheduyet} />
              <div style={{ color: "#515855", fontWeight: 600, marginTop: 15 }}>
                CHỜ PHÊ DUYỆT
              </div>
              <div style={{ marginTop: 15 }}>
                Bạn đã hoàn thành bước chụp hình hệ <br /> thống đang kiểm tra
                và xử lý.
              </div>
            </div>
          )}

          {+statusCheckOrder === 3 && (
            <div style={{ textAlign: "center" }}>
              <img src={Hoantat} />
              <div style={{ color: "#515855", fontWeight: 600, marginTop: 15 }}>
                HOÀN TẤT
              </div>
              <div style={{ marginTop: 15 }}>Đơn hàng đã được hoàn tất.</div>
            </div>
          )}
        </div>
      </Animated>
    </>
  );
};

export default FrontLeft;
