import React, { useContext } from "react";
import { Modal } from "antd";
import HuongDan from "./../images/hdchup.svg";
import Camera from "./../images/cameraOrder.svg";
import Step from "./../images/step.svg";
import { Animated } from "react-animated-css";

const MDUserManual = (props) => {
  return (
    <div style={{ fontSize: 14 }}>
      <Animated
        animationIn="slideInUp"
        animationInDuration={2000}
        isVisible={true}
      >
        <Modal
          className="custom-modal-antd-camera2"
          style={{
            maxWidth: "100%",
            margin: 0,
            verticalAlign: "bottom",
            padding: 0,
          }}
          bodyStyle={{ minHeight: "100vh" }}
          centered={true}
          footer={false}
          closable={false}
          open={props.isModalVisible}
          onCancel={props.handleCancel}
          maskStyle={{ background: "#000000", opacity: 0.8 }}
        >
          <img src={Step} style={{ width: "100%" }} />
          <div
            style={{
              padding: "10px",
              textAlign: "center",
              fontSize: 18,
              fontWeight: 600,
              borderBottom: "1px solid #f1f1f1",
            }}
          >
            Hướng dẫn chụp ảnh xe hiện tại
          </div>
          <img src={HuongDan} style={{ width: "100%", padding: 10 }} />
          <div style={{ margin: "10px 20px" }}>
            <div style={{ color: "#515855" }}>
              <b>CHỤP HÌNH ẢNH XE HIỆN TẠI</b>
            </div>
            <div style={{ color: "#646D69" }}>
              Đảm bảo hình ảnh chính xác với thời điểm hiện tại (tối thiểu 5
              hình)
            </div>
          </div>
          <img
            onClick={() => props.handleCancel()}
            src={Camera}
            style={{ width: 80, margin: "10px 20px" }}
          />
        </Modal>
      </Animated>
    </div>
  );
};

export default MDUserManual;
