import React, { useContext } from "react";
import { Modal, Button } from "antd";
import HuongDan from "./../images/thanhcong.svg";
import { AuthContext } from "./../index";

const Success = (props) => {
  const { postMessage } = useContext(AuthContext);

  const css = {
    divButton: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: "15px",
      alignItems: "center",
    },
    button: {
      width: "95%",
      height: 40,
      borderRadius: 7,
      background: "#009B4B",
      borderColor: "#009B4B",
      color: "#FFF",
      fontWeight: 600,
    },
  };
  return (
    <div style={{ fontSize: 14 }}>
      <Modal
        style={{
          margin: 0,
          padding: 0,
        }}
        className="modal-custom"
        centered={true}
        footer={false}
        visible={props.isModalVisible}
        // onCancel={props.handleCancel}
        closable={false}
        maskStyle={{ background: "#000000", opacity: 0.8 }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={HuongDan} style={{ width: "70px" }} />
          <div
            style={{
              fontSize: 18,
              marginTop: 10,
              fontWeight: 600,
              color: "#383D3B",
            }}
          >
            Thành Công
          </div>
          <div style={{ fontSize: 16, marginTop: 10, color: "#515855" }}>
          Hình ảnh xe đã được gửi thành công
          </div>
          <div style={css.divButton}>
            <Button onClick={() => {
              window.location.reload();
              props.handleCancel();
              }} style={css.button}>
              Đóng
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Success;
